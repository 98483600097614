import { toast } from "@/Hooks/Functions";
import HomeLayout from "@/Layouts/HomeLayout";
import { Head } from "@inertiajs/react";
import "@resources/css/hero.css";
import { promptResponse } from "@state/home";
import { state } from "@state/store";
import { useAtom } from "jotai";
import { useEffect } from "react";
import { useSnapshot } from "valtio";
import BuyFinishProductBlock from "./partials/BuyFinishProductBlock";
import CategoriesBlock from "./partials/CategoriesBlock";
import CodeFinderAndService from "./partials/CodeFinderAndService";
import CreateDesignBlock from "./partials/CreateDesignBlock";
import CustomizeDesignBlock from "./partials/CustomizeDesignBlock";
import HomeCanvas from "./partials/HomeCanvas";
import Overlay from "./partials/Overlay";
import Partners from "./partials/PartnersBlock";

export default function Index({ auth, samplePrompt, categories, predesignProducts }) {

    const snap = useSnapshot(state);
    const [promptRes, setResponse] = useAtom(promptResponse);

    useEffect(() => {
        const channel = window.Echo.channel(`leonardo.ai.${promptRes?.uuid}`);
        channel.listen('.leonardo-image-created-event', ({ data }) => {
            toast('Image created successfully', 'success')
            setResponse(data);
        })
        return () => {
            !!channel && channel.stopListening('leonardo-image-created-event');
            window.Echo.leaveChannel(`leonardo.ai.${promptRes?.uuid}`);
        };
    }, [promptRes])


    return (
        <HomeLayout>
            <Head title="Home" />
            <div className="md:max-h-[90vh] max-h-screen relative w-screen home-page overflow-hidden">
                <HomeCanvas />
                <Overlay />
                <div className="color-options absolute bottom-2 right-2">
                    {snap.colors.map((color) => (
                        <div
                            key={color}
                            className={`circle`}
                            style={{ background: color }}
                            onMouseEnter={() => (state.color = color)}
                            onClick={() => (state.color = color)}
                        ></div>
                    ))}
                </div>
            </div>

            <CodeFinderAndService />

            <CreateDesignBlock auth={auth} samplePrompt={samplePrompt} />

            <BuyFinishProductBlock predesignProducts={predesignProducts} />

            <CustomizeDesignBlock />

            <CategoriesBlock categories={categories} />

            <Partners />
        </HomeLayout>
    );
}
